import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [2,5,9];

export const dictionary = {
		"/(app)": [10,[2],[3]],
		"/(app)/admin": [11,[2,4],[3]],
		"/(app)/admin/ai-studio": [12,[2,4],[3]],
		"/(app)/admin/default-templates": [~13,[2,4],[3]],
		"/(app)/admin/default-text-templates": [~14,[2,4],[3]],
		"/(app)/admin/organisation": [~15,[2,4],[3]],
		"/(app)/admin/roles": [~16,[2,4],[3]],
		"/(app)/analytics": [17,[2],[3]],
		"/auth/login": [~40,[8]],
		"/auth/register": [41,[8]],
		"/auth/verify": [42,[8]],
		"/(app)/calendar": [~18,[2],[3]],
		"/(app)/calendar/[id]": [~19,[2],[3]],
		"/(app)/library": [20,[2],[3]],
		"/(app)/onboarding": [21,[2],[3]],
		"/(app)/profile": [~22,[2],[3]],
		"/(app)/reports": [~23,[2],[3]],
		"/(app)/scheduler": [24,[2,5],[3]],
		"/(app)/scheduler/[tab]": [~25,[2,5],[3]],
		"/(app)/settings": [26,[2,6],[3,7]],
		"/(app)/settings/billing": [~27,[2,6],[3,7]],
		"/(app)/settings/billing/success": [28,[2,6],[3,7]],
		"/(app)/settings/brand-profile": [~29,[2,6],[3,7]],
		"/(app)/settings/brands": [~30,[2,6],[3,7]],
		"/(app)/settings/image-templates": [~31,[2,6],[3,7]],
		"/(app)/settings/organisation": [~32,[2,6],[3,7]],
		"/(app)/settings/prompt-settings": [~33,[2,6],[3,7]],
		"/(app)/settings/roles": [~34,[2,6],[3,7]],
		"/(app)/settings/social-accounts": [~35,[2,6],[3,7]],
		"/(app)/settings/text-templates": [~36,[2,6],[3,7]],
		"/user/accept-invite": [~43,[9]],
		"/user/blank": [44,[9]],
		"/user/new": [~45,[9]],
		"/user/settings": [46,[9]],
		"/user/verify": [~47,[9]],
		"/(app)/workflows": [37,[2],[3]],
		"/(app)/workflows/[workflowId]": [~38,[2],[3]],
		"/(app)/workflows/[workflowId]/[taskId]": [~39,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';